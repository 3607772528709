/*
 * @xiajianbing
 * @Date: 2024-11-19 09:04:55
 * @LastEditTime: 2024-11-19 14:31:48
 */
/**
 * 公共配置文件
 */
module.exports = {
    environment: "build",                      //打包部署服务器的时候，请把environment换成build
    apiUrl: "https://service.singstor.com",        //打包部署服务器的时候，请把apiUrl的localhost换成你的ip,如http://12.34.56.78:3030,不要使用localhost
    //apiUrl: "http://localhost:3030",
    aesKey: "鑫云十数年，专注于企业级高性能视音频、图像存储研发",
    aesIv: "鑫云十数年，专注于企业级高性能视音频、图像存储研发"
}
